import React, { useEffect, useState } from "react";
import { Routes, Route} from "react-router-dom";
import Admin from "./Admin";
import Login from "./Login";
import useStore from "./State Managements/store";
import { isUser } from "./Api/calls";
import { message, Spin, Flex } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";


export default function App() {
  const { sign, setSign, signInfo, setSignInfo } = useStore();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const checkUserAuth = async () => {
    try {
      const response = await isUser();
      setSignInfo(response);
      setLoading(false);
      setSign(true);
    } catch (error) {
      setSign(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    checkUserAuth();
  }, []);

  if (loading===true) {
    return (
      <div className="w-screen h-screen flex flex-col justify-center items-center">
      <Flex align="center" gap="middle">
    <Spin
      indicator={
        <LoadingOutlined
          style={{
            fontSize: 60,
          }}
          spin
        />
      }
    />
  </Flex>
  </div>
    );
  } else {
    return (
      <Routes>
        <Route path="/" element={sign ? <Navigate to="/admin" replace={true} /> : <Navigate to="/login" replace={true} />} />
        <Route path="/login" element={sign ? <Navigate to="/admin" replace={true} /> : <Login/>} />
        <Route path="/admin" element={sign ? <Admin /> : <Navigate to="/login" replace={true} />} />
        <Route
          path="*"
          element={
            <div className="text-[50px] text-center h-full flex flex-col justify-center">
              404 Sayfa Bulunamadı
            </div>
          }
        />

      </Routes>
    );
  }
}

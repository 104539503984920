import { create } from "zustand";

const useStore = create((set) => ({
  //////// GENEL STATE
  api: "https://seashellapp-8feia.ondigitalocean.app/",
  sign: null,
  identity_no: "",
  password: "",
  allMembers: null,
  role:"",
  allUsers:null,
  signInfo:null,

  setSign: (sign) => set(() => ({ sign })),
  setIdentity_no: (identity_no) => set(() => ({ identity_no })),
  setPassword: (password) => set(() => ({ password })),
  setAllMembers: (allMembers) => set(() => ({ allMembers })),
  setRole: (role) => set(() => ({ role })),
  setAllUsers: (allUsers) => set(() => ({ allUsers })),
  setSignInfo: (signInfo) => set(() => ({ signInfo })),
  //////////
}));

export default useStore;

import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  DatePicker,
  Select,
  InputNumber,
  message,
  Popconfirm,
} from "antd";
import dayjs from "dayjs";
import "dayjs/locale/tr"; // Import Turkish locale for dayjs
import locale from "antd/es/date-picker/locale/tr_TR"; // Import Turkish locale for Ant Design
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { getAllUsers, deleteUser } from "../../Api/calls";
import useStore from "../../State Managements/store";
import { isVisible } from "@testing-library/user-event/dist/utils";

const { Option } = Select;

export default function UsersTable() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selecteduser, setselecteduser] = useState(null);
  const [form] = Form.useForm();
  const { allUsers, setAllUsers } = useStore();

  useEffect( () => {
    getUsers();
  }, [])

  const getUsers  =async () =>{
    setAllUsers(await getAllUsers());
  }
 
  const showModal = (person) => {
    setselecteduser(person);
    setIsModalVisible(true);
    form.setFieldsValue({
      ...person,
      membershipDate: dayjs(person.membershipDate),
      lastVisitDate: dayjs(person.lastVisitDate),
      lastMagazineVisitDate: dayjs(person.lastMagazineVisitDate),
      lastPresidentVisitDate: dayjs(person.lastPresidentVisitDate),
    });
  };



  const handleDelete = async (userId) => {
    try {
      await deleteUser(userId);
      message.success("Kullanıcı başarıyla silindi!");
      setAllUsers(await getAllUsers());
    } catch (error) {

      message.error(`Kullanıcı silme başarısız!!! ${error.response?.data?.hata} `);
    }
  };


  const columns = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
    },
    {
      title: "TC Kimlik No",
      dataIndex: "identity_no",
      key: "identity_no",
     
    },
    {
      title: "Adı Soyadı",
      dataIndex: "name_surname",
      key: "name_surname",
    },
    {
      title: "Şifresi",
      dataIndex: "password",
      key: "password",
    },
    {
      title: "Yetkisi",
      dataIndex: "role",
      key: "role",
      fixed: "right",
    },
    {
      title: "İşlemler",
      key: "actions",
      width: 150,
      render: (_, record) => (
        <div className="flex flex-row justify-center items-center gap-4">
          <Popconfirm
          
            title="Bu kullanıcıyı silmek istediğinizden emin misiniz?"
            onConfirm={() => handleDelete(record._id)}
            okText="Evet"
            cancelText="Hayır"
          >
            <DeleteOutlined
              className=" text-[18px] bg-red-100 p-2 border rounded-md border-transparent text-red-500"
              type="link"
            />
          </Popconfirm>
        </div>
      ),
      fixed: "right",
    },
  ];

  const renderTable = () => (
    <Table
      className="users-table-divi w-full h-fit lg:pl-[200px] lg:pr-[200px] pl-10 pr-10"
      scroll={{ x: 'max-content' }}
      dataSource={allUsers}
      columns={columns}
      rowKey="_id"
      pagination={{
        pageSize: 5,
      }}
    />
  );
  

  return (
    <div className="w-full h-fit flex flex-col items-center justify-center ">
      {
       renderTable()
      }
    </div>
  );
}

import axios from 'axios';

const API_BASE_URL = 'https://seashellapp-8feia.ondigitalocean.app/api';

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refreshToken');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  if (refreshToken) {
    config.headers['x-refresh-token'] = refreshToken;
  }
  return config;
});

axiosInstance.interceptors.response.use((response) => {
  const newAccessToken = response.headers['x-new-access-token'];
  if (newAccessToken) {
    localStorage.setItem('token', newAccessToken);
  }
  return response;
});

export const getAllMembers = async () => {
  try {
    const response = await axiosInstance.get('/members/member');
    return response.data;
  } catch (error) {
    console.error('Error fetching members:', error);
    throw error;
  }
};

export const createMember = async (memberData) => {
  try {
    const response = await axiosInstance.post('/members/member', memberData);
    return response.data;
  } catch (error) {
    console.error('Error creating member:', error);
    throw error;
  }
};

export const deleteMember = async (memberId) => {
  try {
    const response = await axiosInstance.delete(`/members/member/${memberId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting member:', error);
    throw error;
  }
};

export const updateMember = async (id, memberData) => {
  try {
    const response = await axiosInstance.post(`/members/member/${id}`, memberData);
    return response.data;
  } catch (error) {
    console.error('Error updating member:', error);
    throw error;
  }
};

export const createUser = async (UserData) => {
  try {
    const response = await axiosInstance.post('/users/signup', UserData);
    return response.data;
  } catch (error) {
    console.error('Error creating user:', error);
    throw error;
  }
};

export const loginUser = async (userData) => {
  try {
    const response = await axiosInstance.post('/users/login', userData);
    const { token, refreshToken } = response.data;
    localStorage.setItem('token', token);
    localStorage.setItem('refreshToken', refreshToken);
    return response.data;
  } catch (error) {
    console.error('Error login:', error);
    throw error;
  }
};

export const getAllUsers = async () => {
  try {
    const response = await axiosInstance.get('/users/allUsers');
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const deleteUser = async (userId) => {
  try {
    const response = await axiosInstance.delete(`/users/delete/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting user:', error);
    throw error;
  }
};

export const isUser = async () => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('Token bulunamadı');
  }

  try {
    const response = await axiosInstance.get('/users/isUser');
    return response.data;
  } catch (error) {
    console.error('Error checking user authentication:', error);
    throw error;
  }
};

export const logoutUser = async () => {
  try {
    const response = await axiosInstance.post('/users/logout');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    return response.data;
  } catch (error) {
    console.error('Çıkış yapılırken hata:', error);
    throw error;
  }
};

import React, { useEffect } from "react";
import { Form, Input, Button, message } from "antd";
import useStore from "./State Managements/store";
import { loginUser, isUser } from "./Api/calls";
import { useNavigate } from "react-router-dom";



export default function Login() {
  const { identity_no, password, setIdentity_no, setPassword, sign, setSign,setSignInfo } = useStore();
  const [formUserSign] = Form.useForm();
  const navigate = useNavigate();



 

  const onFinish = async (values) => {
    try {
      const response = await loginUser(values);

      setSignInfo(response);
        message.success(response.mesaj); 
        setSign(true);
        navigate('/admin', { replace: true });
     
      
    } catch (error) {
      console.error("Giriş başarısız:", error.response?.data?.hata || error.message);
      message.error(error.response?.data?.hata);
    }
  };
 


  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 bg-white shadow-md rounded">
        <h2 className="text-2xl font-bold text-center mb-8">Giriş Yap</h2>
        <Form
        form={formUserSign}
          name="login"
          initialValues={{ identity_no:null, password:null }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="identity_no"
            rules={[
              { required: true, message: "Lütfen TC kimlik numaranızı giriniz!" },
              { min: 11, message: "TC kimlik numarası 11 karakter olmalıdır!" },
              { max: 11, message: "TC kimlik numarası 11 karakter olabilir!" },
              { pattern: /^[0-9]+$/, message: "TC kimlik numarası sadece rakamlardan oluşmalıdır!" }
            ]}
          >
            <Input
              placeholder="TC Kimlik No"
              value={identity_no}
              onChange={(e) => setIdentity_no(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              { required: true, message: "Lütfen şifrenizi giriniz!" },
            ]}
          >
            <Input.Password
              placeholder="Şifre"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="w-full">
              Giriş
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  DatePicker,
  Select,
  InputNumber,
  message,
  Popconfirm,
  Space
} from "antd";
import dayjs from "dayjs";
import "dayjs/locale/tr"; // Import Turkish locale for dayjs
import locale from "antd/es/date-picker/locale/tr_TR"; // Import Turkish locale for Ant Design
import { EditOutlined, DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { getAllMembers, deleteMember, updateMember } from "../../Api/calls";
import useStore from "../../State Managements/store";
import Highlighter from "react-highlight-words";
import * as XLSX from 'xlsx'; // Import the xlsx library


const getColumnSearchProps = (dataIndex, searchInputRef, handleSearch, handleReset, searchedColumn) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={searchInputRef}
        placeholder={`Ara...`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Ara
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Sıfırla
        </Button>
      </Space>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : '',
      onFilterDropdownOpenChange: visible => {
    if (visible) {
      setTimeout(() => searchInputRef.current.select(), 100); 
    }
  },
  render: text =>
    searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchedColumn]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    ),
});



export default function MembersTable() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedMember, setselectedMember] = useState(null);
  const [form] = Form.useForm();
  const { allMembers, setAllMembers, signInfo } = useStore();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInputRef = useRef(null);

  useEffect(() => {
    // Fetch all members when the component mounts
    const fetchMembers = async () => {
      try {
        const members = await getAllMembers();
        setAllMembers(members);
    
      } catch (error) {
        console.error("Error fetching members:", error);
      }
    };

    fetchMembers();
  }, [setAllMembers]);


  const showModal = (person) => {
    setselectedMember(person);
    setIsModalVisible(true);
    form.setFieldsValue({
      ...person,
      membershipDate: dayjs(person.membershipDate),
      lastVisitDate: dayjs(person.lastVisitDate),
      lastMagazineVisitDate: dayjs(person.lastMagazineVisitDate),
      lastPresidentVisitDate: dayjs(person.lastPresidentVisitDate),
      lastEditor: signInfo.name,
    });
  };

  const handleCancel = () => {
    form.resetFields(); // Reset form fields
    setIsModalVisible(false);
    setselectedMember(null);
  };

  const onFinish = async (values) => {
    const updatedValues = {
      ...values,
      lastEditor: signInfo.name, // Add the last editor value here
    };

    try {
      await updateMember(selectedMember._id, updatedValues);
      setAllMembers(await getAllMembers());
      message.success("Üye bilgileri başarıyla güncellendi!");
      form.resetFields(); // Reset form fields
      setIsModalVisible(false);
      setselectedMember(null);
    } catch (error) {
      console.error("Üye kaydı güncelleme başarısız :", error);
      message.error("Üye kaydı güncelleme başarısız!");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    message.error("Kayıt başarısız!");
  };

  const handleDelete = async (memberId) => {
    if (signInfo.role === "Yönetici") {
      try {
        await deleteMember(memberId);
        message.success("Üye başarıyla silindi!");
        setAllMembers(await getAllMembers());
      } catch (error) {
        console.error("Üye silme başarısız :", error);
        message.error("Üye silme başarısız!");
      }
    } else {
      message.error("yetkiniz yok");
    }
  };

  const handlePhoneInput = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    const formattedValue = value.replace(
      /^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})$/,
      (_, g1, g2, g3, g4, g5) => {
        let result = "";
        if (g1) result += g1;
        if (g2) result += ` (${g2}`;
        if (g3) result += `) ${g3}`;
        if (g4) result += ` ${g4}`;
        if (g5) result += ` ${g5}`;
        return result;
      }
    );
    form.setFieldsValue({ phone: formattedValue });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };



  const columns = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
      hidden:true,
      ...getColumnSearchProps("_id", searchInputRef, handleSearch, handleReset, searchedColumn),
    },
    {
      title: "Adı",
      dataIndex: "firstName",
      key: "firstName",
      fixed: "left",
      ...getColumnSearchProps("firstName", searchInputRef, handleSearch, handleReset, searchedColumn),
    },
    {
      title: "Soyadı",
      dataIndex: "lastName",
      key: "lastName",
      fixed: "left",
      ...getColumnSearchProps("lastName", searchInputRef, handleSearch, handleReset, searchedColumn),
    },
    {
      title: "Kategorisi",
      dataIndex: "category",
      key: "category",
      ...getColumnSearchProps("category", searchInputRef, handleSearch, handleReset, searchedColumn),
    },
    {
      title: "Telefonu",
      dataIndex: "phone",
      key: "phone",
      ...getColumnSearchProps("phone", searchInputRef, handleSearch, handleReset, searchedColumn),
    },
    {
      title: "Adresi",
      dataIndex: "address",
      key: "address",
      ...getColumnSearchProps("address", searchInputRef, handleSearch, handleReset, searchedColumn),
    },
    {
      title: "Üyelik Tarihi",
      dataIndex: "membershipDate",
      key: "membershipDate",
     
      ...getColumnSearchProps("membershipDate", searchInputRef, handleSearch, handleReset, searchedColumn),
      render: (text) => dayjs(text).format("DD.MM.YYYY"),
    },
    {
      title: "Son Ziyaret Tarihi",
      dataIndex: "lastVisitDate",
      key: "lastVisitDate",
      
      ...getColumnSearchProps("lastVisitDate", searchInputRef, handleSearch, handleReset, searchedColumn),
      render: (text) => dayjs(text).format("DD.MM.YYYY"),
    },
    {
      title: "Son Dergi Ziyareti Tarihi",
      dataIndex: "lastMagazineVisitDate",
      key: "lastMagazineVisitDate",
      
      ...getColumnSearchProps("lastMagazineVisitDate", searchInputRef, handleSearch, handleReset, searchedColumn),
      render: (text) => dayjs(text).format("DD.MM.YYYY"),
    },
    {
      title: "Son Bağış (₺)",
      dataIndex: "lastDonation",
      key: "lastDonation",
      ...getColumnSearchProps("lastDonation", searchInputRef, handleSearch, handleReset, searchedColumn),
    },
    {
      title: "Toplam Bağış (₺)",
      dataIndex: "totalDonation",
      key: "totalDonation",
      ...getColumnSearchProps("totalDonation", searchInputRef, handleSearch, handleReset, searchedColumn),
    },
    {
      title: "Başkanın Son Ziyaret Tarihi",
      dataIndex: "lastPresidentVisitDate",
      key: "lastPresidentVisitDate",
      
      ...getColumnSearchProps("lastPresidentVisitDate", searchInputRef, handleSearch, handleReset, searchedColumn),
      render: (text) => dayjs(text).format("DD.MM.YYYY"),
    },
    {
      title: "Hakkında Not",
      dataIndex: "member_note",
      key: "member_note",
      ...getColumnSearchProps("member_note", searchInputRef, handleSearch, handleReset, searchedColumn),
    },
    {
      title: "Son Verilen Dergi",
      dataIndex: "lastMagazine",
      key: "lastMagazine",
      ...getColumnSearchProps("lastMagazine", searchInputRef, handleSearch, handleReset, searchedColumn),
    },
    {
      title: "Son Düzenleyen",
      dataIndex: "lastEditor",
      key: "lastEditor",
      ...getColumnSearchProps("lastEditor", searchInputRef, handleSearch, handleReset, searchedColumn),
    },
    {
      title: "Actions",
      key: "actions",
      width: 150,
      render: (_, record) => (
        <div className="flex flex-row justify-center items-center gap-4">
          <EditOutlined
            className="text-[18px] bg-blue-100 p-2 border rounded-md border-transparent text-blue-500"
            type="link"
            onClick={() => showModal(record)}
          />
          {signInfo.role === "Yönetici" ? (
            <Popconfirm
              title="Üyeyi gerçekten silmek istiyor musunuz?"
              onConfirm={() => handleDelete(record._id)}
              okText="Evet"
              cancelText="Hayır"
            >
              <DeleteOutlined
                className="text-[18px] bg-red-100 p-2 border rounded-md border-transparent text-red-500"
                type="link"
              />
            </Popconfirm>
          ) : null}
        </div>
      ),
      fixed: "right",
    },
  ];

  const columnMapping = {
    _id: "ID",
    firstName: "Adı",
    lastName: "Soyadı",
    category: "Kategorisi",
    phone: "Telefonu",
    address: "Adresi",
    membershipDate: "Üyelik Tarihi",
    lastVisitDate: "Son Ziyaret Tarihi",
    lastMagazineVisitDate: "Son Dergi Ziyareti Tarihi",
    lastDonation: "Son Bağış (₺)",
    totalDonation: "Toplam Bağış (₺)",
    lastPresidentVisitDate: "Başkanın Son Ziyaret Tarihi",
    member_note: "Hakkında Not",
    lastMagazine:"Son Verilen Dergi",
    lastEditor: "Son Düzenleyen",
    createdAt: "Oluşturma Tarihi",
    updatedAt: "Güncelleme Tarihi",
    __v: "Versiyon",
  };
  

  const transformData = (data) => {
    return data.map(item => {
      let transformedItem = {};
      for (let key in item) {
        if (columnMapping[key]) {
          transformedItem[columnMapping[key]] = item[key];
        } else {
          transformedItem[key] = item[key];
        }
      }
      return transformedItem;
    });
  };
  
  const exportToExcel = () => {
    const transformedData = transformData(allMembers);
    const worksheet = XLSX.utils.json_to_sheet(transformedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Members");
    XLSX.writeFile(workbook, "members.xlsx");
  };

  return (
    <div className="w-full h-fit flex flex-col items-center justify-center">
      <div className="w-full flex flex-row justify-end items-end lg:pl-[200px] lg:pr-[200px] pl-10 pr-10">
      <Button type="primary" onClick={exportToExcel} style={{ marginBottom: 20 }}>
        Excel Çıktısı Al
      </Button>
      </div>
      <Table
        className="members-table-divi w-full h-fit lg:pl-[200px] lg:pr-[200px] pl-10 pr-10"
        scroll={{ x: "max-content" }}
        dataSource={allMembers}
        columns={columns}
        rowKey="_id"
        pagination={{
          pageSize: 10,
        }}
      />
      <Modal
        title="Üye Bilgileri"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          form={form}
          name="view"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            membershipDate: dayjs(),
            lastVisitDate: dayjs(),
            lastMagazineVisitDate: dayjs(),
            lastPresidentVisitDate: dayjs(),
            lastEditor: signInfo.name,
          }}
        >
          <Form.Item
            name="firstName"
            label="Adı"
            rules={[{ required: true, message: "Lütfen adınızı giriniz!" }]}
          >
            <Input disabled={signInfo.role === "Yönetici" ? false : true} />
          </Form.Item>

          <Form.Item
            name="lastName"
            label="Soyadı"
            rules={[{ required: true, message: "Lütfen soyadınızı giriniz!" }]}
          >
            <Input disabled={signInfo.role === "Yönetici" ? false : true} />
          </Form.Item>

          <Form.Item
            name="category"
            label="Kategorisi"
            rules={[{ required: true, message: "Lütfen kategoriyi giriniz!" }]}
          >
            <Input disabled={signInfo.role === "Yönetici" ? false : true} />
          </Form.Item>

          <Form.Item
            name="phone"
            label="Telefonu"
            rules={[
              { required: true, message: "Lütfen telefon numaranızı giriniz!" },
              {
                pattern: /^0 \(\d{3}\) \d{3} \d{2} \d{2}$/,
                message: "Geçerli bir telefon numarası giriniz!",
              },
            ]}
          >
            <Input
              onChange={handlePhoneInput}
              disabled={signInfo.role === "Yönetici" ? false : true}
            />
          </Form.Item>

          <Form.Item
            name="address"
            label="Adresi"
            rules={[{ required: true, message: "Lütfen adresinizi giriniz!" }]}
          >
            <Input disabled={signInfo.role === "Yönetici" ? false : true} />
          </Form.Item>

          <Form.Item
            name="membershipDate"
            label="Üyelik Tarihi"
            rules={[
              { required: true, message: "Lütfen üyelik tarihini seçiniz!" },
            ]}
          >
            <DatePicker
              format="YYYY-MM-DD"
              locale={locale}
              disabled={signInfo.role === "Yönetici" ? false : true}
            />
          </Form.Item>

          <Form.Item
            name="lastVisitDate"
            label="Son Ziyaret Tarihi"
            rules={[
              {
                required: false,
                message: "Lütfen son ziyaret tarihini seçiniz!",
              },
            ]}
          >
            <DatePicker format="YYYY-MM-DD" locale={locale} />
          </Form.Item>

          <Form.Item
            name="lastMagazineVisitDate"
            label="Son Dergi Ziyareti Tarihi"
            rules={[
              {
                required: false,
                message: "Lütfen son dergi ziyaret tarihini seçiniz!",
              },
            ]}
          >
            <DatePicker format="YYYY-MM-DD" locale={locale} />
          </Form.Item>

          <Form.Item
            name="lastDonation"
            label="Son Bağış (₺)"
            rules={[
              {
                required: false,
                message: "Lütfen son bağış miktarını giriniz!",
              },
            ]}
          >
            <InputNumber min={0} />
          </Form.Item>

          <Form.Item
            name="totalDonation"
            label="Toplam Bağış (₺)"
            rules={[
              {
                required: false,
                message: "Lütfen toplam bağış miktarını giriniz!",
              },
            ]}
          >
            <InputNumber min={0} />
          </Form.Item>

          <Form.Item
            name="lastPresidentVisitDate"
            label="Başkanın Son Ziyaret Tarihi"
            rules={[
              {
                required: false,
                message: "Lütfen başkanın son ziyaret tarihini seçiniz!",
              },
            ]}
          >
            <DatePicker format="YYYY-MM-DD" locale={locale} />
          </Form.Item>

          <Form.Item
            name="member_note"
            label="Hakkında Not"
            rules={[{ required: false, message: "Lütfen notunuzu giriniz!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="lastMagazine"
            label="Son Verilen Dergi"
            rules={[{ required: false, message: "Bu veri zorunludur" }]}
            
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="lastEditor"
            label="Şuan Düzenleyen"
            rules={[{ required: false, message: "Bu veri zorunludur" }]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Kaydet
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
